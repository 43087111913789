import { type ChangeEvent, lazy, useState } from 'react';
import axios from 'axios';
import SimpleAlert from './_react/_shared/SimpleAlert/SimpleAlert';

const Loading = lazy(() => import('@wayste/sour-ui').then((module) => ({ default: module.Loading })));

export const cleanAndValidateEmail = (email: string) => {
    const trimmedEmail = email.trim();
    const VALID_EMAIL =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const isValid = VALID_EMAIL.test(trimmedEmail);
    if (isValid) return trimmedEmail;
    return null;
};

export const subscribeToNewsletter = async (email: string, listID: string, formName: string) => {
    const url = 'https://vey55hrd4a.execute-api.us-east-1.amazonaws.com/production/subscribe-to-newsletter';
    const response = await axios({
        method: 'POST',
        url: url,
        data: JSON.stringify({ email, listID, otherData: {} }),
    });
    if (response?.status === 200 && response?.data?.status === 200) {
        if (window) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Form_Submit_Network_Success',
                formName: formName,
            });
        }
        return { status: 'success' };
    }
    if (window) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'Form_Submit_Network_Error',
            formName: formName,
        });
    }
    return { status: 'error' };
};

const NewsLetterForm = ({ formName }: { formName: string }) => {
    const [subscribeEmailValue, setSubscribeEmailValue] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSubscribeEmailValue(value);
        const validEmail = cleanAndValidateEmail(value);
        if (validEmail) setEmailInvalid(false);
    };

    const handleEmailSubscribe = async () => {
        setEmailInvalid(false);
        const validEmail = cleanAndValidateEmail(subscribeEmailValue);
        if (!validEmail) {
            setEmailInvalid(true);
            return;
        }
        setEmailLoading(true);
        const response = await subscribeToNewsletter(validEmail, '8b08f53c-4d27-4ba8-b0c9-0699a8d0ed83', formName);
        if (response.status === 'success') {
            setShowSuccessAlert(true);
        } else if (response.status === 'error') {
            setShowErrorAlert(true);
        }
        setEmailLoading(false);
    };

    return (
        <>
            {/* Modals */}
            <SimpleAlert
                open={showSuccessAlert}
                closeDialog={() => setShowSuccessAlert(false)}
                title="Success!"
                msg="You're Signed Up!"
                severity="success"
                key={'success-alert'}
            />
            <SimpleAlert
                open={showErrorAlert}
                closeDialog={() => setShowErrorAlert(false)}
                title="Error"
                msg="An Error Occurred"
                severity="error"
                key={'error-alert'}
            />
            <div className="relative flex w-full flex-nowrap justify-center gap-2">
                <input
                    type="email"
                    placeholder="Your email"
                    className={`w-full rounded-md border bg-neutral-800 px-6 py-2 text-xs text-neutral-200 focus:outline-none md:text-base ${
                        emailInvalid ? 'border-red-500' : 'border-neutral-700'
                    }`}
                    onChange={(e) => handleEmailInputChange(e)}
                    value={subscribeEmailValue}
                    onKeyDown={(e) => {
                        if (e.key === ' ' || e.keyCode === 32) {
                            e.preventDefault();
                        }
                    }}
                />
                {emailInvalid ? (
                    <div className="absolute -bottom-5 left-6 ml-1 mt-1 text-xs text-red-500">Invalid email please try again</div>
                ) : null}
                <button disabled={emailLoading} className="btn-primary px-4" onClick={handleEmailSubscribe}>
                    <span className="group-disabled:invisible">Subscribe</span>
                    <Loading size="h-4 w-4" className={`text-sourgum-greyblue-900 ml-1 ${emailLoading ? '' : 'hidden'}`} />
                </button>
            </div>
        </>
    );
};

export default NewsLetterForm;
